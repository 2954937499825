import { Sidebar } from '@fingo/lib/components/layout';
import { hasShowSubRoutes, userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { useGetUser, useSelectedCompany } from '@fingo/lib/hooks';
import React from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { Home, Profile } from '../views';
import { collectionRoutes } from '../features/collection/routes';
import { commercialInvoiceRoutes, commercialRoutes } from '../features/commercial/routes';
import { confirmingRoutes } from '../features/confirming/routes';
import { profileRoutes } from '../features/profile/routes';
import { riskRoutes } from '../features/risk/routes';
import { supportRoutes } from '../features/support/routes';
import { treasuryRoutes } from '../features/treasury/routes';
import { pricingRoutes } from '../features/pricing/routes';
import fundsRoutes from '../features/funds';
import legalRoutes from '../features/legal';
import DebtorFactoring from '../features/collection/components/Debtor/DebtorFactoring';
import DebtorOrdering from '../features/collection/components/Debtor/DebtorOrdering';

const useRouteSetter = () => {
  const user = useGetUser();
  const selectedCompany = useSelectedCompany();
  const country = useGetCountryFromUrl();
  if (!user) return [];
  return [
    {
      id: 'lucila-home',
      label: 'Home',
      path: '/app/home',
      component: <Home />,
      disabled: false,
      show: true,
      showInNavbar: true,
    },
    {
      id: 'comercial',
      label: 'Comercial',
      path: '/app/comercial',
      component: <Sidebar options={commercialRoutes(user, country)} />,
      disabled: false,
      show: true,
      showInNavbar: true,
      subRoutes: commercialRoutes(user, country),
    },
    {
      id: 'collection',
      label: 'Cobranza',
      path: '/app/collection',
      component: <Sidebar options={collectionRoutes(user, country)} />,
      disabled: false,
      showInNavbar: true,
      show: true,
      subRoutes: collectionRoutes(user),
    },
    {
      id: 'risk',
      label: 'Riesgo',
      path: '/app/risk',
      disabled: false,
      component: <Sidebar options={riskRoutes(user, country)} />,
      showInNavbar: true,
      show: true,
      subRoutes: riskRoutes(user, country),
    },
    {
      id: 'support',
      label: 'Soporte',
      path: '/app/support',
      disabled: false,
      component: <Sidebar options={supportRoutes(user)} />,
      showInNavbar: true,
      show: true,
      subRoutes: supportRoutes(user),
    },
    {
      id: 'treasury',
      label: 'Tesorería',
      path: '/app/treasury',
      component: <Sidebar options={treasuryRoutes(user)} />,
      disabled: false,
      showInNavbar: true,
      show: true,
      subRoutes: treasuryRoutes(user),
    },
    {
      id: 'confirming',
      label: 'Confirming',
      path: '/app/confirming',
      component: <Sidebar options={confirmingRoutes(user)} />,
      disabled: false,
      show: true,
      showInNavbar: true,
      subRoutes: confirmingRoutes(user),
    },
    {
      id: 'pricing',
      label: 'Pricing',
      path: '/app/pricing',
      component: <Sidebar options={pricingRoutes(user)} />,
      disabled: false,
      show: true,
      showInNavbar: true,
      subRoutes: pricingRoutes(user),
    },
    {
      id: 'profile',
      label: 'Perfil',
      path: '/app/profile',
      component: <Profile />,
      disabled: false,
      show: true,
      showInNavbar: false,
      subRoutes: profileRoutes(selectedCompany),
    },
    {
      id: 'funds',
      label: 'Fondos',
      path: '/app/funds',
      component: <Sidebar options={fundsRoutes(user)} />,
      disabled: false,
      show: true,
      showInNavbar: true,
      subRoutes: fundsRoutes(user),
    },
    {
      id: 'invoice-profile',
      label: 'Perfil Factura',
      path: '/app/invoice-profile',
      component: <Sidebar options={commercialInvoiceRoutes} />,
      disabled: false,
      show: true,
      showInNavbar: false,
      subRoutes: commercialInvoiceRoutes,
    },
    {
      id: 'debtor-profile-factoring',
      label: 'Perfil de Cliente Factoring',
      path: '/app/invoice-debtor/:debtorId/:dateToPay?/:currency?',
      component: <DebtorFactoring />,
      disabled: false,
      show: userHasRoutePermission(user, 'collection.view_collectionmanager'),
      showInNavbar: false,
    },
    {
      id: 'debtor-profile-ordering',
      label: 'Perfil de Cliente Ordering',
      path: '/app/ordering-debtor/:debtorId',
      component: <DebtorOrdering />,
      disabled: false,
      show: userHasRoutePermission(user, 'collection.view_collectionmanager'),
      showInNavbar: false,

    },
    {
      id: 'legal',
      label: 'Legal',
      path: '/app/legal',
      component: <Sidebar options={legalRoutes(user)} />,
      disabled: false,
      show: true,
      showInNavbar: true,
      subRoutes: legalRoutes(user),
    },
  ];
};

const useLucilaRoutes = () => {
  /* In order to change fewer code we define a base routing case */
  const routes = useRouteSetter();
  const user = useGetUser();
  if (!user) return [];
  return routes.filter(({ show, subRoutes }) => {
    if (subRoutes === undefined && show) return true;
    if (!show) return false;
    return hasShowSubRoutes(user, subRoutes);
  });
};

export default useLucilaRoutes;
